import React, { useEffect } from 'react'
import Details from '../components/Details'
import Footer from '../components/Footer'
import Heading from '../components/Heading'
import Navbar from '../components/Navbar'

const AboutUs = ({ dashboard }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {/* ------------- header --------------- */}
            <Navbar />
            {/* ============= About Us ============= */}
            {/* --------- Heading ----------- */}
            <Heading heading={'About Us'} subHeading={"Let's know the D'Forcepower"} />
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <img src="/images/about_us.png" alt="abotuUS" className='img-fluid p-4' />
                    </div>
                    <div className="col-lg-6">
                        <h2>Who We are ?</h2>
                        <p className="text-muted lh-base h6">DFORCEpower is devoted to the designing, manufacturing, marketing, and distribution of Professional Audio/Video equipment suited for the Indian market. Our products are include Line Array, Loudspeaker Systems, Power Amplifiers, Digital Processors and Mixer Consoles, DJ Effect Lights, Arichitechral Lighting etc.</p>
                        <h2 className='mt-3'>What we are doing ?</h2>
                        <p className="text-muted lh-base h6">A widespread dealer’s network stretching across the farther corners of the state & country makes DFORCEpower products available to all. Every product of the company incorporates innovative technology, and manufactured under stringent quality control techniques exacting standards of the Indian Market. We can our products are market responsive, cost effective and meet the demands of future technology.
                        </p>

                        <p className="text-muted lh-base h6">
                            We continue to consistently create products with unmatched performance, unflinching reliability and unwavering support. Our products are aesthetically designed to please, coupled with user friendly operations and experienced after sales service support.</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row my-5">
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className='h1 text-center'>Our mission</h2>
                            <p className='text-muted lh-base h6'>Mission, Values & Vision Since the founding of Akash Electronics in 2001, professional audio monitoring has been the core of our business. Our unrivalled commitment to research and development has resulted in a number of industry firsts and established DFORCEpower as the industry leader in active monitors. Over 15 years later Akash Electronics monitoring products remain true to our original philosophy, offering reliability, neutral sound reproduction regardless of size, as well as the ability to adapt frequency response to the listening environment. We are faithful to our founding principles. We also want to maintain our leading position in our core business with neutrally sounding products for any monitoring need from mono to multi-channel reproduction. Our clients receive paramount support in all aspects of the field, from acoustical advice and calibration services to technical service and long product life span. Buying a DFORCEpower product is the soundest investment in audio monitoring. We want to be unique as a company and strive to exceed expectations in all aspects of our business. This means offering first class products and service to our end customers and business partners alike. During the past decade our business has expanded beyond the professional studio monitor speaker segment to selected consumer markets. Our products are found in an increasing number of home theaters and residential systems bringing soundtracks alive, as they were when recorded. Although the applications may vary, one thing remains the same: the desire to have the best possible sound reproduction. We are proud to contribute with our sound origins and profound knowledge of what it takes to make it right.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row my-5">
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className='h1 text-center'>Management Experience</h2>
                            <p className='text-muted lh-base h6'>We got experience from Akash Electronics, and has maintained the relation with that company. who, done it encouraged by that company. is being entered us, in this upper business. in DFORCEpower This company is worked, in Power amplifier repairing. selling, Speakers repairing. that will give us the raw material for our company. The company has also given the guarantee to supply the electronics components having, Capacitor, Transistor, Connector, Wire & others electronics goods which to be processed up The professional sound equipments. is used are Public addressing systems, cinema, venue functions, music clubs, film shootings, D.J Programs, schools, mega structures installations, & other musical or announcing, publicity, and other sound attached programs.etc</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* ============ Details ============ */}
            <Details />
            {/* =========== Footer =========== */}
            <Footer dashboard={dashboard} />
        </>
    )
}

export default AboutUs